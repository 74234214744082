import { useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import SectionHeader from "./SectionHeader";
import SuccessMessage from "./SuccessMessage";
import { postRsvp } from "./api";

const DAIRY_FREE = "dairyFree";
const GLUTEN_FREE = "glutenFree";
const VEGETARIAN = "vegetarian";
const VEGAN = "vegan";

const DEFAULT_DIET_REQ = {
  [DAIRY_FREE]: false,
  [GLUTEN_FREE]: false,
  [VEGETARIAN]: false,
  [VEGAN]: false,
};

function RSVP() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [rsvp, setRsvp] = useState("yes");
  const [entree, setEntree] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [dietReq, setDietReq] = useState(DEFAULT_DIET_REQ);

  const hasValidEmail = /(.+)@(.+){2,}\.(.+){2,}/.test(email);

  const yes = rsvp === "yes";
  const isSubmitEnabled =
    Boolean(name) && hasValidEmail && (!yes || Boolean(entree));

  const tooltip = !Boolean(name)
    ? "Please enter your name"
    : !hasValidEmail
    ? "Please enter a valid email address"
    : yes && !Boolean(entree)
    ? "Please pick an entree"
    : "";

  const onSubmit = async () => {
    setIsSubmitting(true);

    const resetInputValues = () => {
      setName("");
      setEmail("");
      setRsvp("yes");
      setEntree("");
      setDietReq(DEFAULT_DIET_REQ);
    };

    try {
      const res = await postRsvp({
        name,
        email,
        attending: yes,
        entree,
        dairyFree: dietReq[DAIRY_FREE],
        glutenFree: dietReq[GLUTEN_FREE],
        vegetarian: dietReq[VEGETARIAN],
        vegan: dietReq[VEGAN],
      });

      if (res.status === 200 || res.status === 201) {
        const message =
          res.status === 200
            ? "Your response has been updated"
            : "Received. Thanks!";

        setSuccessMessage(message);
        resetInputValues();
      } else {
        alert(
          "Hmm, something went wrong. Contact Derek and say 'Hey, what gives?'"
        );
      }
    } catch (error) {
      console.log("error", error);
      alert(
        "Hmm, something went wrong. Contact Derek and say, 'Hey, what gives?'"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const onDietReqChange = (e) =>
    setDietReq((currDietReq) => ({
      ...currDietReq,
      [e.target.name]: e.target.checked,
    }));

  return (
    <div id="rsvp" className="App-section">
      <SectionHeader>RSVP</SectionHeader>
      <p>
        Please RSVP for each person in your party individually so that we can
        record your meal option. If you are unable to attend, please feel free
        to RSVP as a group.
      </p>
      <form
        disabled={!isSubmitEnabled}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="Rsvp-section">
          <Typography variant="h5">
            Name<sup>*</sup>
          </Typography>
          <TextField
            classes={{ root: "Rsvp-name" }}
            onChange={(e) => setName(e.target.value)}
            value={name}
            required
          />
        </div>
        <div className="Rsvp-section">
          <Typography variant="h5">
            Email Address<sup>*</sup>
          </Typography>
          <TextField
            classes={{ root: "Rsvp-name" }}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            type="email"
            required
          />
        </div>
        <div className="Rsvp-section">
          <Typography variant="h5">
            Can you make it?<sup>*</sup>
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="rsvp"
              value={rsvp}
              name="rsvp-buttons-group"
              onChange={(e) => setRsvp(e.target.value)}
            >
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label="I'll be there with bells on!"
              />
              <FormControlLabel
                value="no"
                control={<Radio />}
                label="Me and my bells will be elsewhere"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="Rsvp-section">
          <Typography variant="h5">
            Entree Selection<sup>*</sup>
          </Typography>
          <FormControl component="fieldset" disabled={!yes}>
            <RadioGroup
              aria-label="meal-choice"
              name="meal-buttons-group"
              onChange={(e) => setEntree(e.target.value)}
              value={entree}
              row
            >
              <FormControlLabel
                value="chicken"
                control={<Radio />}
                label="Black Truffle Chicken / Potato Puree / Bacon / Grilled Broccolini / Sage Jus"
              />
              <FormControlLabel
                value="salmon"
                control={<Radio />}
                label="Crispy-Skin Salmon / Potato Puree / Spinach Fondue / Olive Tapenade"
              />
              <FormControlLabel
                value="vegetarian"
                control={<Radio />}
                label="Vegetarian - Chef's Choice Pasta"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="Rsvp-section">
          <Typography variant="h5">Dietary Requirements</Typography>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!yes}
                  name={DAIRY_FREE}
                  checked={dietReq[DAIRY_FREE]}
                  onChange={onDietReqChange}
                />
              }
              label="Dairy-Free"
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!yes}
                  name={GLUTEN_FREE}
                  checked={dietReq[GLUTEN_FREE]}
                  onChange={onDietReqChange}
                />
              }
              label="Gluten-Free"
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!yes}
                  name={VEGETARIAN}
                  checked={dietReq[VEGETARIAN]}
                  onChange={onDietReqChange}
                />
              }
              label="Meat-Free"
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!yes}
                  name={VEGAN}
                  checked={dietReq[VEGAN]}
                  onChange={onDietReqChange}
                />
              }
              label="Animal-Product-Free"
            />
          </FormGroup>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip title={tooltip}>
            <span>
              <LoadingButton
                disabled={!isSubmitEnabled || isSubmitting}
                loading={isSubmitting}
                size="large"
                type="submit"
                variant="contained"
              >
                Submit
              </LoadingButton>
            </span>
          </Tooltip>
        </div>
      </form>
      <SuccessMessage
        message={successMessage}
        open={Boolean(successMessage)}
        onClose={() => setSuccessMessage(null)}
      />
    </div>
  );
}

export default RSVP;
