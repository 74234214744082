import SectionHeader from "./SectionHeader";

function GettingHere() {
  return (
    <div id="getting-here" className="App-section">
      <SectionHeader>Getting Here</SectionHeader>
      <p>
        Snowmass Village is in the Roaring Fork Valley and you will generally
        need a car to get around (or at least plan to carpool with someone who
        has one). There is no reliable Uber/Lyft service.
      </p>
      <br />
      <h4>Flying</h4>
      <ul>
        <li>
          DEN: Denver International Airport has the most flights at the best
          prices but is a 4-hour drive to Snowmass Village. Traffic through the
          mountains can be very slow heading up Friday afternoon and back Sunday
          afternoon. Keep that in mind when booking flights. Cars are the
          easiest way to get to the valley but there are also shuttle options
          like the Bustang.
        </li>
        <li>
          ASE: Aspen Regional Airport is located just 10-15 min from the venue
          but flights are limited and pricing can really vary. It's the easiest
          way to fly into the area.
        </li>
        <li>
          EGE/GJT: There are also regional airports in Eagle (1.5 hrs to
          Snowmass Village) and Grand Junction (2+hrs to Snowmass Village).
        </li>
      </ul>
    </div>
  );
}

export default GettingHere;
