import { Link } from "@mui/material";
import SectionHeader from "./SectionHeader";

function createMarkup() {
  return {
    __html: `
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12365.859841991522!2d-106.9446729!3d39.2096046!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe5c4bfa6ac0c05d3!2sViceroy%20Snowmass!5e0!3m2!1sen!2sus!4v1638638608508!5m2!1sen!2sus"
      width="100%"
      height="400"
      style="border:0;"
      allowfullscreen=""
      loading="lazy"
    >
    </iframe>
  `,
  };
}

function Where() {
  return (
    <div id="venue" className="App-section">
      <SectionHeader>The Venue &ndash; Viceroy Snowmass</SectionHeader>
      <p>
        <Link
          href="https://www.viceroyhotelsandresorts.com/snowmass"
          target="_blank"
        >
          Viceroy Snowmass
        </Link>{" "}
        is located at the base of Snowmass Mountain in Snowmass Village. The
        ceremony will be held on the Ballroom Terrace behind The Viceroy at 4 pm
        on Saturday, July 30th. Please arrive at 3:30 PM to be seated
        beforehand. The cocktail hour and reception will be hosted at the Nest
        Patio at the Viceroy directly after.
      </p>
      <br />
      <div dangerouslySetInnerHTML={createMarkup()}></div>
      <h4>Parking</h4>
      <ul>
        <li>
          Free Parking: there is free parking at the Limelight Hotel at the
          Snowmass Base Village Garage
          <ul>
            <li>
              Due to construction, plan for at least 10 minutes to get over the
              Viceroy. From the parking garage, go up to the base village, then
              walk north across the plaza to One Snowmass. Take the stairs or
              elevator in One Snowmass to the bottom floor. Exit One Snowmass to
              the north and then turn right, east, and follow Wood Rd past the
              Limelight up to The Viceroy.
            </li>
            <li>There is no overnight parking at The Limelight</li>
          </ul>
        </li>
        <li>
          Valet Parking: The Viceroy offers day-of valet parking for $15/car or
          overnight valet parking for $35.
        </li>
      </ul>
    </div>
  );
}

export default Where;
