import { useEffect, useRef } from "react";

import { ATTEMPTS, WORD_LENGTH, getWordState } from "./constants";

function Box({
  index,
  letter = "",
  setAwaitTransition = () => {},
  stateClassName,
}) {
  const boxRef = useRef(null);
  const delay = index * 0.5;
  const otherDelay = delay + 0.25;

  useEffect(() => {
    if (stateClassName) {
      boxRef.current.classList.add("guess");
      boxRef.current.classList.add(stateClassName);
    }
  }, [stateClassName]);

  useEffect(() => {
    if (boxRef.current && index === WORD_LENGTH - 1) {
      boxRef.current.addEventListener("transitionend", () => {
        setAwaitTransition(false);
      });
    }
  }, [boxRef, index, setAwaitTransition]);

  return (
    <div
      className="box"
      ref={boxRef}
      style={{
        transition: `transform 1s ${delay}s, background-color 2s ${otherDelay}s, border-color 2s ${otherDelay}s`,
      }}
    >
      <h2>{letter}</h2>
    </div>
  );
}

function Row({
  currentInputRowRef,
  isGuess,
  letters = "",
  setAwaitTransition,
}) {
  let wordState;

  if (isGuess) {
    wordState = getWordState(letters);
  }

  return (
    <div className="row" ref={currentInputRowRef}>
      {new Array(WORD_LENGTH).fill(0).map((_, i) => (
        <Box
          key={i}
          index={i}
          letter={letters[i]}
          setAwaitTransition={setAwaitTransition}
          stateClassName={wordState?.[i]}
        />
      ))}
    </div>
  );
}

function GameBoard({
  currentInputRowRef,
  currentWord,
  guesses,
  setAwaitTransition,
  tooltipRef,
}) {
  const remainingAttempts = ATTEMPTS - guesses.length - 1;

  return (
    <div className="board">
      {guesses.map((guess, i) => (
        <Row
          key={i}
          isGuess
          letters={guess}
          setAwaitTransition={setAwaitTransition}
        />
      ))}
      <div className="tooltip-wrapper">
        <Row currentInputRowRef={currentInputRowRef} letters={currentWord} />
        <div className="error-tooltip" ref={tooltipRef} />
      </div>
      {remainingAttempts > 0 &&
        new Array(remainingAttempts).fill(0).map((_, i) => <Row key={i} />)}
    </div>
  );
}

export default GameBoard;
