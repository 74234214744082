import ValleyMapImg from "./valley_map.png";

function ValleyMap() {
  return (
    <div className="App-section">
      <div>
        <img
          alt="valley-map"
          style={{ width: "100%", height: "auto" }}
          src={ValleyMapImg}
        />
      </div>
    </div>
  );
}

export default ValleyMap;
