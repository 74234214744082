import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import allowedWords from "./allowed-words.json";
import {
  CORRECT,
  TARGET,
  WORD_LENGTH,
  getLetterState,
  isTransitioning,
} from "./constants";
import KeyBoard from "./KeyBoard";
import GameBoard from "./GameBoard";
import "../App.css";
import "./ThankYou.css";

function Header({ hasMuchWin, placeholder }) {
  const blurredTextRef = useRef(null);

  useEffect(() => {
    console.log("%cNO CHEATING, MICHAEL!", "color:red; font-size:40px");
  }, []);

  useEffect(() => {
    if (hasMuchWin) {
      blurredTextRef.current.classList.add("unblur");
    }
  }, [hasMuchWin]);

  return (
    <div style={{ textAlign: "center" }}>
      <h1 style={{ margin: 0 }}>Thank you so much!</h1>
      <h2 style={{ marginTop: "1vh", marginBottom: "1vh" }}>
        Your joining us on our special day is a "
        <span className="placeholder">{placeholder}</span>"&nbsp;&nbsp;
      </h2>
      <h1 style={{ margin: 0 }}>
        <span className="blurry-text" ref={blurredTextRef}>
          It means a great deal!
        </span>
      </h1>
    </div>
  );
}

const MemoHeader = memo(Header, isTransitioning);

function ThankYou() {
  const [allLetters, setAllLetters] = useState({});
  const [awaitTransition, setAwaitTransition] = useState(false);
  const [currentWord, setCurrentWord] = useState("");
  const [guesses, setGuesses] = useState([]);
  const currentInputRowRef = useRef(null);
  const tooltipRef = useRef(null);
  const wordRef = useRef("");
  const hasMuchWin = guesses.includes(TARGET);

  wordRef.current = currentWord;

  const placeholder = useMemo(() => {
    return TARGET.split("")
      .map((c, index) => {
        for (let i = 0; i < guesses.length; i++) {
          const guess = guesses[i];

          if (c === guess[index]) {
            return c;
          }
        }
        return "_";
      }, [])
      .join("");
  }, [guesses]);

  useEffect(() => {
    if (guesses.length > 0) {
      setAllLetters((all) => {
        const newAll = { ...all };
        for (let i = 0; i < WORD_LENGTH; i++) {
          const letter = guesses[guesses.length - 1][i];
          const letterState = getLetterState(letter, i);

          if (newAll[letter] !== CORRECT) {
            newAll[letter] = letterState;
          }
        }
        return newAll;
      });
    }
  }, [guesses]);

  const hideTooltip = () => {
    tooltipRef.current.textContent = "";
    tooltipRef.current.style.display = "none";
  };

  const handleInputError = useCallback((tooltip) => {
    const currentTooltip = tooltipRef.current.textContent;

    if (tooltip !== currentTooltip) {
      tooltipRef.current.textContent = tooltip;
      tooltipRef.current.style.display = "block";

      setTimeout(hideTooltip, 4000);
    }

    currentInputRowRef.current.classList.add("row-shake");

    setTimeout(() => {
      currentInputRowRef.current.classList.remove("row-shake");
    }, 1000);
  }, []);

  const handleLetterInput = useCallback((letter) => {
    hideTooltip();
    if (wordRef.current.length >= WORD_LENGTH) return;
    setCurrentWord((w) => w.concat(letter.toLowerCase()));
  }, []);

  const handleDelete = useCallback(() => {
    hideTooltip();
    setCurrentWord((w) => w.substring(0, w.length - 1));
  }, []);

  const handleEnter = useCallback(() => {
    const wordToEval = wordRef.current;

    if (wordToEval.length < WORD_LENGTH) {
      handleInputError("Moar letters");
      return;
    }

    if (!allowedWords.includes(wordToEval)) {
      handleInputError(
        `There are ${allowedWords.length.toLocaleString()} words but "${wordToEval}" ain't one`
      );
      return;
    }

    setAwaitTransition(true);
    setCurrentWord("");
    setGuesses((g) => [...g, wordToEval]);
  }, [handleInputError]);

  const onKeyDown = useCallback(
    (e) => {
      const isDelete = e.key === "Backspace" || e.key === "Delete";
      const isEnter = e.key === "Enter";

      if (hasMuchWin) return;
      if (isDelete) return handleDelete();
      if (isEnter) return handleEnter();

      const alphaTest = new RegExp(/^[a-zA-z]{1}$/);

      if (alphaTest.test(e.key)) {
        handleLetterInput(e.key);
      }
    },
    [hasMuchWin, handleLetterInput, handleDelete, handleEnter]
  );

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => window.removeEventListener("keydown", onKeyDown);
  }, [onKeyDown]);

  return (
    <div className="App App-main thank-you-body">
      <MemoHeader
        awaitTransition={awaitTransition}
        hasMuchWin={hasMuchWin}
        placeholder={placeholder}
      />
      <div className="board-wrapper">
        <GameBoard
          currentInputRowRef={currentInputRowRef}
          currentWord={currentWord}
          guesses={guesses}
          setAwaitTransition={setAwaitTransition}
          tooltipRef={tooltipRef}
        />
        <KeyBoard
          awaitTransition={awaitTransition}
          allLetters={allLetters}
          disabled={hasMuchWin}
          handleDelete={handleDelete}
          handleEnter={handleEnter}
          handleLetterInput={handleLetterInput}
        />
      </div>
    </div>
  );
}

export default ThankYou;
