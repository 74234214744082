import { memo } from "react";
import { Button } from "@mui/material";

import { COLORS, isTransitioning } from "./constants";

const KEY_BUTTON_PROPS = {
  disableRipple: true,
  variant: "outlined",
  style: {
    color: "white",
    margin: "0 2px",
    height: "5vh",
    maxHeight: "54px",
    minHeight: "44px",
    width: "6vw",
    maxWidth: "54px",
    minWidth: "32px",
    borderColor: "transparent",
    padding: 0,
  },
  onKeyDown: (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  },
};

function Key({ className = "tbd", letter, onClick }) {
  return (
    <Button
      {...KEY_BUTTON_PROPS}
      onClick={onClick}
      className={`key-${className}`}
      style={{
        ...KEY_BUTTON_PROPS.style,
        backgroundColor: COLORS[className],
      }}
    >
      {letter}
    </Button>
  );
}

function KeyBoard({
  allLetters,
  disabled,
  handleDelete,
  handleEnter,
  handleLetterInput,
}) {
  return (
    <div className={disabled ? "keyboard keyboard-disabled" : "keyboard"}>
      {["qwertyuiop", "asdfghjkl"].map((letterSet, i) => (
        <div key={i} className="keyboard-row">
          {letterSet.split("").map((letter) => (
            <Key
              key={letter}
              className={allLetters[letter]}
              letter={letter}
              onClick={() => handleLetterInput(letter)}
            />
          ))}
        </div>
      ))}
      <div className="keyboard-row">
        <Button
          {...KEY_BUTTON_PROPS}
          onClick={() => handleEnter()}
          style={{
            ...KEY_BUTTON_PROPS.style,
            minWidth: "44px",
          }}
        >
          Enter
        </Button>
        {"zxcvbnm".split("").map((letter) => (
          <Key
            key={letter}
            className={allLetters[letter]}
            letter={letter}
            onClick={() => handleLetterInput(letter)}
          />
        ))}
        <Button
          {...KEY_BUTTON_PROPS}
          onClick={() => handleDelete()}
          style={{
            ...KEY_BUTTON_PROPS.style,
            minWidth: "44px",
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
}

export default memo(KeyBoard, isTransitioning);
