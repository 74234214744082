import { useEffect, useState } from "react";
import {
  Dialog,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { getRsvps } from "./api";

const RsvpPopup = ({ open, handleClose }) => {
  const [rsvps, setRsvps] = useState([]);

  useEffect(() => {
    const fetchRsvps = async () => {
      setRsvps(await getRsvps());
    };

    if (open) {
      fetchRsvps();
    }
  }, [open]);

  return (
    <Dialog fullWidth maxWidth open={open} onClose={handleClose}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Entree</TableCell>
            <TableCell>Dairy Free</TableCell>
            <TableCell>Gluten Free</TableCell>
            <TableCell>Vegan</TableCell>
            <TableCell>Vegetarian</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rsvps.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                {row.attending ? (
                  <Icon fontSize="small">done</Icon>
                ) : (
                  <Icon fontSize="small">sentiment_dissatisfied</Icon>
                )}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.entree}</TableCell>
              <TableCell>
                {row.dairyFree ? <Icon fontSize="small">done</Icon> : null}
              </TableCell>
              <TableCell>
                {row.glutenFree ? <Icon fontSize="small">done</Icon> : null}
              </TableCell>
              <TableCell>
                {row.vegan ? <Icon fontSize="small">done</Icon> : null}
              </TableCell>
              <TableCell>
                {row.vegetarian ? <Icon fontSize="small">done</Icon> : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Dialog>
  );
};

export default RsvpPopup;
