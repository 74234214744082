import SectionHeader from "./SectionHeader";

function StyledPrice({ n }) {
  return <i style={{ fontWeight: 300 }}>{new Array(n).fill("$")}</i>;
}

function WhereToStay() {
  return (
    <div id="where-to-stay" className="App-section">
      <SectionHeader>Where To Stay</SectionHeader>
      <p>
        The Roaring Fork Valley (Aspen/Swowmass) is an expensive area to travel
        to and stay. We understand that the cost may be prohibitive for some of
        our family and friends, so if it's not workable we completely
        understand. We'll happily celebrate together with you in the future!
      </p>
      <p>
        We've tried to recommend a range of options across price ranges. Please
        book your lodging as soon as possible as rates typically go up in the
        summer and there is a fair over that weekend in Carbondale.
      </p>
      <p>
        Snowmass Village is where the wedding will be held so that will be the
        area to stay in if you want to be closest to the venu (note: (Old)
        Snowmass, CO is further away from Snowmass Village and does not directly
        connect through). Aspen and Basalt are 15-20 min away. Carbondale and
        Glenwood Springs are 30-40 minutes away.
      </p>
      <br />
      <h4>
        Viceroy Snowmass <StyledPrice n={4} />
      </h4>
      <p>
        Our venue, the Viceroy, includes beautiful lodging right on the
        mountain… but it's expensive. We have a room discount through June 15
        (rooms will more than $500 per night even with the discount). To access
        the room discount please call the Viceroy at (970) 923-8000 and
        reference the "Kostanick/Young Wedding."
      </p>
      <br />
      <h4>
        Timberline Snowmass <StyledPrice n={2} />
      </h4>
      <p>
        The Timberline is also located in Snowmass Village and a quick drive
        from the Viceroy (about 1.2 miles). We have a discount/block of rooms
        through May 27th - rooms range from $182 per night (studio) to $406 per
        night (up to 6 people). To book a room, email
        reservations@thetimberline.com or call (970) 923-4000 or (800) 922-4001
        and reference the "Kostanick/Young Wedding."
      </p>
      <br />
      <h4>
        Basalt Hotels <StyledPrice n={3} />
      </h4>
      <p>
        There are a few hotels in Basalt. Most of them look pretty pricey that
        weekend. Keep in mind if you search for hotels in the area that the
        hotel is actually located in Basalt and not over in Eagle which is close
        as the crow-flies but is a long drive.
      </p>
      <br />
      <h4>
        Glenwood Springs Hotels/Motels <StyledPrice n={2} />
      </h4>
      <p>
        Most accommodations in Glenwood Springs are about 50 minutes from the
        venue at the Viceroy but offer cheaper accommodations than further up
        the valley. Rooms are booking up quickly. If you search for hotels in
        Glenwood Springs, make sure they are near Glenwood Springs and not in
        areas too far up or down the I-70 corridor.
      </p>
      <br />
      <h4>
        Airbnb <StyledPrice n={2} />
        &ndash;
        <StyledPrice n={4} />
      </h4>
      <p>
        There are some Airbnb options throughout the valley that could be a good
        option for groups.
      </p>
    </div>
  );
}

export default WhereToStay;
