import { useState } from "react";
import Button from "@mui/material/Button";

import splash1500 from "./splash1500.jpg";
import splash375 from "./splash375.jpg";
import "./App.css";
import GettingHere from "./GettingHere";
import Header from "./Header";
import Nav from "./Nav";
import RSVP from "./RSVP";
import RsvpPopup from "./RsvpPopup";
import QuickInfo from "./QuickInfo";
import ThankYou from "./ThankYou";
import ThingsToDo from "./ThingsToDo";
import ValleyMap from "./ValleyMap";
import Where from "./Where";
import WhereToStay from "./WhereToStay";

const code = "meganishot";

function App() {
  const [unlocked, setUnlocked] = useState(false);
  const [open, setIsOpen] = useState(false);
  const [step, setStep] = useState(0);

  if (window.location.pathname === "/thankyou") {
    return <ThankYou />;
  }

  return (
    <div
      className="App"
      onKeyPress={(e) => {
        if (e.key === code[step]) {
          setStep((s) => s + 1);
        }

        if (e.key === "Enter") {
          if (step === code.length) {
            setUnlocked(true);
            setStep(0);
          } else {
            setStep(0);
          }
        }
      }}
      tabIndex={0}
    >
      <main className="App-main">
        {unlocked && (
          <Button
            onClick={() => setIsOpen(true)}
            style={{ position: "fixed", top: 4, left: 4 }}
          >
            Show RSVPs
          </Button>
        )}
        <RsvpPopup handleClose={() => setIsOpen(false)} open={open} />
        <Nav className="App-nav-skinny" />
        <Header className="App-skinny-header" />
        <div
          className="App-left"
          style={{ backgroundImage: `url(${splash1500}), url(${splash375})` }}
        ></div>
        <div className="App-right">
          <Nav className="App-nav" />
          <Header className="App-header" />
          <QuickInfo />
          <Where />
          <RSVP />
          <GettingHere />
          <WhereToStay />
          <ThingsToDo />
          <ValleyMap />
        </div>
      </main>
    </div>
  );
}

export default App;
