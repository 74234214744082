import { Link } from "@mui/material";

import SectionHeader from "./SectionHeader";

function ThingsToDo() {
  return (
    <div id="things-to-do" className="App-section">
      <SectionHeader>Things to Do</SectionHeader>
      <p>
        There is so much to see and do in the valley &ndash; we hope you can
        take in some of the fun while you're in town.
      </p>
      <ul>
        <li>
          <Link
            href="https://www.carbondalearts.com/mountain-fair"
            target="_blank"
          >
            Carbondale Mountain Fair
          </Link>
          &nbsp;&ndash; a beloved local tradition the fair will have music,
          entertainment, and booths Saturday, 7/30 and Sunday, 7/31.
        </li>
        <li>
          <Link
            href="https://www.alltrails.com/us/colorado/snowmass-village"
            target="_blank"
          >
            Hiking
          </Link>
          &nbsp;&ndash; there are hiking trails of all types and skill levels in
          Snowmass Village, Carbondale, and Aspen. Let us know if you need a
          recommendation!
        </li>
        <li>
          Downtown Aspen - check out the fancy stores, grab a bite to eat, and
          take in the old town charm.
        </li>
        <li>
          <Link
            href="https://aspenchamber.org/plan-trip/trip-highlights/maroon-bells/reservations"
            target="_blank"
          >
            Maroon Bells
          </Link>
          &nbsp;&ndash; a short drive from Aspen/Snowmass. Tickets and shuttle
          must be booked in advanced during the summer.
        </li>
        <li>
          Mountain biking &ndash; check out the amazing trails at&nbsp;
          <Link
            href="https://www.gosnowmass.com/mountain-biking-sky-mountain-park/"
            target="_blank"
          >
            Sky Mountain Park
          </Link>
          &nbsp;in Snowmass or the Prince Creek trail system near Carbondale.
        </li>
        <li>
          Ride the lift up Snowmass &ndash; take the Elk Camp Gondola or Elk
          Camp Lift up the mountain to access beautiful hiking trails and views.
        </li>
        <li>
          <Link href="https://www.hotspringspool.com/" target="_blank">
            Glenwood Hot Springs
          </Link>
          /Glenwood Springs &ndash; take a soak in the hot springs and walk
          around old town Glenwood Springs.
        </li>
        <li>
          <Link
            href="https://aspenchamber.org/blog/everything-you-need-know-about-independence-pass"
            target="_blank"
          >
            Independence Pass
          </Link>
          &nbsp;&ndash; drive up through Aspen towards Independence Pass for
          beautiful views of the high peaks. Not for the faint of heart, the
          road is narrow!
        </li>
        <li>
          Golf &ndash; we don't golf but there's lots of golf around at Aspen
          Golf Course in Aspen and River Valley Ranch in Carbondale.
        </li>
        <li>
          Marble, CO &ndash; fun, old town with a marble quarry about 40 min
          from Carbondale / 1h 15 min from Snowmass Village. Slow Groovin' has
          excellent barbeque!
        </li>
        <li>
          Fly fishing &ndash; we've never been but if that's your thing, it's
          supposed to be great!
        </li>
        <li>
          <Link
            href="https://aspenhistory.org/tours-sites/ashcroft-ghost-town/"
            target="_blank"
          >
            Ashcroft Ghost Town
          </Link>
          &nbsp;&ndash; 30 min drive from Snowmass Village; check out this
          historic ghost town.
        </li>
      </ul>
    </div>
  );
}

export default ThingsToDo;
