import { Button } from "@mui/material";

function Nav({ className }) {
  return (
    <div
      className={className}
      style={{ padding: "2vmin 0", textAlign: "center" }}
    >
      <Button href="#venue">Venue</Button>
      <Button href="#rsvp">RSVP</Button>
      <Button href="#getting-here">Getting Here</Button>
      <Button href="#where-to-stay">Where To Stay</Button>
      <Button href="#things-to-do">Things To Do</Button>
      <Button
        href="https://registry.theknot.com/megan-kostanick-derek-young-july-2022-co/48469446"
        variant="link"
        target="_blank"
      >
        Registry
      </Button>
    </div>
  );
}

export default Nav;
