const BACKEND =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : "/.netlify/functions";

export const getRsvps = () =>
  fetch(`${BACKEND}/api/rsvp`).then((res) => res.json());

export const postRsvp = (body) =>
  fetch(`${BACKEND}/api/rsvp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
