export const TARGET = "bargain";
export const WORD_LENGTH = TARGET.length;
export const ATTEMPTS = 7;

export const ABSENT = "absent";
export const PRESENT = "present";
export const CORRECT = "goteem";

export const COLORS = {
  [ABSENT]: "#6e6e70",
  [PRESENT]: "#b59f3b",
  [CORRECT]: "#538d4e",
};

function replaceAt(string, index, char) {
  return string.substring(0, index) + char + string.substring(index + 1);
}

// Test: abandon, barrage
export function getWordState(word) {
  let available = TARGET;
  const result = [];

  for (let i = 0; i < word.length; i++) {
    if (word[i] === available[i]) {
      result[i] = CORRECT;
      available = replaceAt(available, i, "_");
    }
  }

  for (let i = 0; i < word.length; i++) {
    const c = word[i];
    const indexOfChar = available.indexOf(c);

    if (result[i] !== CORRECT) {
      if (indexOfChar >= 0) {
        result[i] = PRESENT;
        available = replaceAt(available, indexOfChar, "_");
      } else {
        result[i] = ABSENT;
      }
    }
  }

  return result;
}

export function getLetterState(letter, index) {
  if (TARGET[index] === letter) {
    return CORRECT;
  }

  return TARGET.includes(letter) ? PRESENT : ABSENT;
}

export function isTransitioning(_, props) {
  return props.awaitTransition;
}
