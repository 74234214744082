import { Icon, Tooltip } from "@mui/material";

import SectionHeader from "./SectionHeader";

function QuickInfo() {
  return (
    <div className="App-section">
      <SectionHeader>
        July 30th, 2022 @ 3:30 PM
        <Tooltip
          title={
            <p styles={{ fontSize: 6 }}>
              * if your name is "Jonathan Cappelli" the wedding is at 2:00 PM
            </p>
          }
        >
          <sup>
            <Icon style={{ fontSize: 12 }}>info</Icon>
          </sup>
        </Tooltip>
      </SectionHeader>
      <p style={{ textAlign: "center" }}>
        Please arrive at 3:30 PM so that we can start the ceremony promptly at
        4:00 PM.
      </p>
      <p style={{ textAlign: "center" }}>
        130 Wood Rd, Snowmass Village, CO 81615
      </p>
      <p style={{ textAlign: "center" }}>Semi-Formal/Dressy Casual Attire</p>
    </div>
  );
}

export default QuickInfo;
